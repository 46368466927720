@use "_modules/variables" as *;
@use "_modules/mixins" as *;

/*! Header css */

/*! Three out of the following four css styles use a color that is sourced from */

/*! a sass variable (efficient tools and techniques). */
.header-image {
  height: calc(66vh - 56px);
  background: $highlight-two url("../img/logo.png") center space;
  background-size: 192px;
}

.hero-text-container {
  background-color: rgba(255, 255, 255, 0.588);
  border-radius: 100px 100px 10px;
  transition: 1s ease-out;
  animation: hero-text-container-text-shadow-move 1.5s ease-out;
  backdrop-filter: blur(4px);
}

.hero-text {
  font-size: 7vw;
  text-shadow: 5px 5px $highlight-two;
  transition: 1s ease-out;
  animation: hero-text-text-shadow-move 1.5s ease-out,
    hero-text-text-move 1.5s ease-out;
}

/*! Hero text animations */

.hero-text:hover,
.hero-text:focus {
  color: rgba(0, 0, 0, 0);
  text-shadow: 10px 20px $highlight-two;
}

.hero-text-container:hover,
.hero-text-container:focus {
  border-radius: 100px 100px 100px 10px;
}

$hero-text-start-animation-x: 160px;
$hero-text-start-animation-y: 120px;

/*! The following three styles use the values '160px' and '120px'. These values are sourced from two sass
variables 'hero-text-start-animation-x' and 'hero-text-start-animation-y' and injected during transpilation
(efficient tools and techniques). */
@keyframes hero-text-text-move {
  0% {
    transform: translate(
      -$hero-text-start-animation-x,
      -$hero-text-start-animation-y
    );
  }

  100% {
    transform: translate(0, 0);
  }
}

@keyframes hero-text-text-shadow-move {
  0% {
    color: rgba(0, 0, 0, 0);
    text-shadow: $hero-text-start-animation-x $hero-text-start-animation-y
      $highlight-two;
  }
  75% {
    color: rgba(0, 0, 0, 0);
  }

  100% {
    color: #000;
    text-shadow: 5px 5px $highlight-two;
  }
}

@keyframes hero-text-container-text-shadow-move {
  0% {
    transform: translate(
      $hero-text-start-animation-x,
      $hero-text-start-animation-y
    );
  }

  100% {
    transform: translate(0, 0);
  }
}

/*! Artist page */

/*! Values generated using https://codepen.io/yaphi1/live/oNbEqGV */

/*! The following four css styles are generated by a sass mixin (efficient tools and techniques). */
@include gen-carousel-text-opacities(0.463 0.404 0.529 0.518);

.carousel-item > .carousel-caption + picture > img {
  object-fit: cover;
  height: calc(100vh - 56px);
  margin: auto;
}

.carousel-item > picture > img {
  object-fit: contain;
  height: calc(100vh - 56px);
  margin: auto;
}

/*! The following four css styles are generated by a sass mixin (efficient tools and techniques). */
@include gen-carousel-image-center(
  50% 38.72%,
  50% 46.79%,
  50% 34.88%,
  50% 29.76%
);
